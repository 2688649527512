const defaultTheme = {
    palette: {
        // For theme with only one color, set primary and secondary as the same
        primary: {
            main: '#3885CD',
            bleached: '#f3f9fd',
        },
        secondary: {
            main: '#3885CD',
        },
        // For tertiary color, use the follwing object. Material UI components won't use it, but CSS will
        //tertiary: {}
    },
    status: {
        danger: 'orange'
    },
    typography: {
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        },
        fontFamily: [
            "Open Sans",
            'Roboto',
            'Arial',
            'sans-serif'
        ].join(',')
    },
    logo: {
        name: 'edpPT',
        src: `${process.env.PUBLIC_URL}/assets/images/logos/edp.png`
    }
};

export default defaultTheme;