import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router'
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Button } from '@save2compete/efz-design-system';
import { useForm, Controller } from 'react-hook-form';
import { BASENAME_URL_LOGIN, BASENAME_URL_OPPORTUNITIES } from "constants/settings";
import {
    getIsUserAuthenticated,
    getUserTypeID
} from "selectors/user";
import { EMAIL_REGEXP, intlMessages, isDefined } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';
import ErrorMessage from 'components/ErrorMessage';

import { ReactComponent as NGBackground } from 'assets/images/auth/svg/login.svg';
import { ReactComponent as PlatformLogo } from 'assets/images/logos/svg/eff-platform.svg';
import useRecoveryPassword from 'hooks/recoveryPassword';
import { StyledLogin, StyledLoginSVG } from '../Login/index.styled';





const ResetPassword = ({
    userTypeID,
    isUserAuthenticated
}) => {

    let navigate = useNavigate();

    const { fetchResetPassword, isRequesting, msgResetPassword } = useRecoveryPassword();

    const {
        handleSubmit,
        control,
        formState: {
            errors,
        },
    } = useForm({
        defaultValues: {
            email: ''
        },
    });


    // componentWillUnmountWithUseEffect
    useEffect(() => {
        return () => {
            toast.dismiss(); // dismiss all notifications
        }
    }, []); //eslint-disable-line

    // if user is authenticated, redirect
    useEffect(() => {
        if (isUserAuthenticated && isDefined(userTypeID)) {
            navigate(BASENAME_URL_OPPORTUNITIES);
        }
    }, [isUserAuthenticated]); //eslint-disable-line

    //handleOnSubmit
    const handleOnSubmit = ({ email }) => {
        fetchResetPassword(email.toLowerCase());
    };



    return (
        <StyledLogin>

            <StyledLoginSVG>
                <div className="login-svg-ng">
                    <NGBackground />
                </div>
            </StyledLoginSVG>

            <section className="login-top-section">

                <div className="app-login-container animated fadeIn animation-duration-2">
                    <div className="login-content m-4">
                        {/* Header */}
                        <div className='login-header'>
                            <PlatformLogo />
                        </div>
                        {/* Body */}
                        <div className="login-body">
                            <div className="login-body-text">
                                <h4><IntlMessages id={'page.resetPassword.subtitle'} /></h4>
                            </div>

                            {/* ResetPassword */}
                            <form className='login-form' autoComplete={'false'} onSubmit={handleSubmit(handleOnSubmit)}>
                                {/* email */}
                                <div className="w-100">
                                    <div className="d-flex flex-column mb-3" >
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{
                                                required: "yup.message.required",
                                                pattern: {
                                                    value: EMAIL_REGEXP,
                                                    message: 'yup.message.email'
                                                }
                                            }}
                                            disabled={isRequesting}
                                            render={({ field: { ref, ...field } }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    placeholder={intlMessages('placeholder.emailExample')}
                                                    disabled={isRequesting}
                                                    error={errors?.email}
                                                />)}
                                        />
                                        {errors?.email && <ErrorMessage error={errors.email} />}
                                        {!!msgResetPassword && <p className="text-center text-info p-0 m-0 mt-3"><IntlMessages id={msgResetPassword} /></p>}
                                    </div>
                                </div>
                                {/*actions*/}
                                <div className="mb-3 d-flex justify-content-center">
                                    <Button
                                        variant="primary"
                                        size='bg'
                                        type="submit"
                                        disabled={isRequesting}
                                        loading={isRequesting}
                                        dataTestId='reset-password'
                                    >
                                        <IntlMessages id={'label.resetPassword'} />
                                    </Button>
                                </div>
                            </form>

                            {/*Go to Login*/}
                            <div className="link-back-login d-flex justify-content-center">
                                <h4>
                                    <Link to={`${BASENAME_URL_LOGIN}`} className={isRequesting ? 'disabled-cursor' : ''}>
                                        <IntlMessages id='label.backLogin' />
                                    </Link>
                                </h4>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <Footer isUserAuthenticated={isUserAuthenticated} /> */}
        </StyledLogin>
    );
};


const mapStateToProps = ({ user }) => {
    return {
        userTypeID: getUserTypeID(user),
        isUserAuthenticated: getIsUserAuthenticated(user),
    }
};

export default connect(mapStateToProps, {})(ResetPassword);
