import { media } from 'constants/styles';
import styled from 'styled-components';

export const StyledLogin = styled.div.attrs({
    className: 'StyledLogin'
})`
    background-color: var(--background-color);
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    .login-body {
        display: flex;
        flex-flow: column nowrap;
        padding: 0 3.125rem 2rem 3.125rem;
        align-items: center;

        ${media.tablet`
            padding: 0 2.5rem 1.5rem 2.5rem;
        `}

        h4 {
            font-size: 0.875rem;
            line-height: 19.07px;
            color: var(--text-color);
            margin-bottom: 1.5rem;
        }

        .link-back-login {
            margin-top: 10px;
        }

        .login-button {
            padding: 0.625rem 2.125rem;
        }

        .login-body-text {
            text-align: center;

            h4 {
                font-weight: 500;
                color: var(--text-color);
                pointer-events: none;
            }
        }

        .login-form {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            width: 80%;
            position: relative;

            h4 a {
                //default value for forgot your password link
                font-weight: 400;
            }

            label {
                color: var(--text-color);
                font-size: 0.875rem;
                margin-bottom: 0.5rem;
                font-weight: 600;
            }

            .change-button {
                position: absolute;
                top: 0.625rem;
                right: -4rem;
                color: var(--primary-color);
                font-size: 0.75rem;
                cursor: pointer;
            }
        }
    }


`;

export const StyledLoginSVG = styled.div.attrs({
    className: 'StyledLoginSVG'
})`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-flow: row nowrap;
    flex: 1;

    .login-svg-ng {
        width: 100%;
        svg {
            height: calc(100vh - 3.25rem); // 3.25rem -> footer
            width: 100%;
        }
    }
`;