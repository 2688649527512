import { useEffect, useRef, useState } from 'react';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import {
    Route,
    Routes,
    useLocation
} from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import CssBaseline from '@mui/material/CssBaseline';
import defaultTheme from 'pages/themes/defaultTheme';
import { IntlProvider } from 'react-intl';
import AppLocale from 'lngProvider/index';

import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/pt";

import "assets/vendors/style";

import Login from 'pages/auth/Login';
import ResetPassword from 'pages/auth/ResetPassword';
import Error404 from 'components/Error404';
import { AVAILABLE_COMPANIES } from "constants/user";
import { BASENAME_URL, BASENAME_URL_LOGIN, BASENAME_URL_AUTH_ADFS, BASENAME_URL_APP, BASENAME_URL_ADFS_TOKEN_EXPIRY } from "constants/settings";
import {
    getLocale,
    getIsDarkTheme
} from 'selectors/settings';

import {
    getUserCompanyID,
    getUserID
} from 'selectors/user';

import muiOverrides from 'constants/muiOverrides';
import CacheBuster from 'pages/CacheBuster';
import { UserProvider } from 'contexts/userContext';
import useRedirectToken from 'hooks/redirectToken';
import LogoutADFS from './auth/LogoutADFS';
import CookieConsent from 'react-cookie-consent';
import CookieBanner from 'components/CookieBanner';
import IntlMessages from 'components/util/IntlMessages';
import CustomizeCookiesDialog from 'components/CustomizeCookiesDialog';
import useCookies from 'hooks/cookies';
import { Button } from '@mui/material';
import Restricted from './Restricted';
import { EfzThemeProvider } from '@save2compete/efz-design-system';
import TokenExpiryADFS from 'pages/auth/tokenExpiryADFS';

const App = ({
    isDarkTheme,
    locale,
    userCompanyID,
}) => {

    const cookieConsentRef = useRef();

    const {
        setCookie,
        PLATFORM_CUSTOMIZATION_COOKIE,
        PERSONALIZED_ADVERTISING_COOKIE
    } = useCookies();

    //Cookies handler to accept consent and other privacy cookies with default values (true)
    const cookiesHandler = () => {
        setCookie(PLATFORM_CUSTOMIZATION_COOKIE, true, 355)
        setCookie(PERSONALIZED_ADVERTISING_COOKIE, true, 365)
        cookieConsentRef.current.accept()
    }

    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const { isValidRedirectToken } = useRedirectToken();
    isValidRedirectToken(searchParams);

    const [isCustomizeDialogOpen, setIsCustomizeDialogOpen] = useState(false);


    //set localePickers locale (locale fro DatePickers, TimePickers etc)
    const currentAppLocale = AppLocale[locale.locale];

    //set localePickers locale (locale fro DatePickers, TimePickers etc)
    moment.locale(currentAppLocale.locale);

    // Gets company thme or defaults to defaultTheme
    const appliedTheme = useRef(createTheme(
        AVAILABLE_COMPANIES?.find(company => company.id === userCompanyID)?.theme ?? defaultTheme
    ));


    useEffect(() => {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    }, []);//eslint-disable-line


    // * THEME SELECTION * \\
    // Can't be inside a useEffect because it only triggers after the component finishes rendering, and we need the theme when it's rendering
    if (isDarkTheme) {
        appliedTheme.current = createTheme(
            { ...appliedTheme.current, palette: { ...appliedTheme.current.palette, mode: 'dark' } }
        );
    }
    else {
        // Material UI theme set
        appliedTheme.current = createTheme(defaultTheme);
        appliedTheme.current = {
            ...appliedTheme.current,
            ...muiOverrides
        };
    }

    // Set CSS Theme colors
    let bodyStyles = document.body.style;
    bodyStyles.setProperty('--primary-color', appliedTheme.current.palette.primary.main);
    bodyStyles.setProperty('--primary-color-light', appliedTheme.current.palette.primary.light);
    bodyStyles.setProperty('--primary-color-dark', appliedTheme.current.palette.primary.dark);
    bodyStyles.setProperty('--primary-color-bleached', appliedTheme.current.palette.primary.bleached);
    bodyStyles.setProperty('--secondary-color', appliedTheme.current.palette.secondary.main);
    bodyStyles.setProperty('--secondary-color-light', appliedTheme.current.palette.secondary.light);
    bodyStyles.setProperty('--secondary-color-dark', appliedTheme.current.palette.secondary.dark);
    if (!!appliedTheme.current.palette.tertiary) {
        bodyStyles.setProperty('--tertiary-color', appliedTheme.current.palette.tertiary.main);
        bodyStyles.setProperty('--tertiary-color-light', appliedTheme.current.palette.tertiary.light);
        bodyStyles.setProperty('--tertiary-color-dark', appliedTheme.current.palette.tertiary.dark);
    }
    bodyStyles.setProperty('--text-color', appliedTheme.current.palette.text.primary);
    bodyStyles.setProperty('--text-color-secondary', appliedTheme.current.palette.text.secondary);
    bodyStyles.setProperty('--divider-color', appliedTheme.current.palette.divider);
    bodyStyles.setProperty('--disabled-color', appliedTheme.current.palette.action.disabled);
    bodyStyles.setProperty('--disabled-bg-color', appliedTheme.current.palette.action.disabledBackground);

    return (
        <CacheBuster location={location}>
            <UserProvider>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={appliedTheme.current}>
                        <EfzThemeProvider theme={appliedTheme.current}>
                            <LocalizationProvider dateAdapter={MomentUtils} locale={locale.localePickers}>
                                <IntlProvider
                                    locale={currentAppLocale.locale}
                                    messages={currentAppLocale.messages}
                                >
                                    <CssBaseline />
                                    <div className="app-main">
                                        <Routes>
                                            <Route path={`${BASENAME_URL_APP}/*`} element={<Restricted />} />
                                            {/* route auth efz */}
                                            <Route path={`${BASENAME_URL}`} element={<Login />} />
                                            <Route path={BASENAME_URL_LOGIN} element={<Login />} />
                                            <Route path={`${BASENAME_URL}reset-password`} element={<ResetPassword />} />
                                            {/* route auth adfs */}
                                            <Route path={BASENAME_URL_ADFS_TOKEN_EXPIRY} element={<TokenExpiryADFS />} />
                                            <Route path={BASENAME_URL_AUTH_ADFS} element={<LogoutADFS />} />
                                            <Route path='*' element={<Error404 />} />
                                        </Routes>
                                        <ToastContainer autoClose={2500} newestOnTop={true} />
                                        <CookieConsent
                                            ref={cookieConsentRef}
                                            buttonStyle={{ display: 'none' }}
                                            containerClasses={'cookie-banner'}
                                            disableStyles={true}
                                            buttonClasses="btn cookie-banner-button MuiButton-root MuiButton-contained"
                                            buttonText={<IntlMessages id='label.cookies.acceptCookies' />}
                                            overlayClasses='cookie'
                                            overlay
                                        >
                                            <div className='d-flex'>
                                                <CookieBanner />
                                                <div className='d-flex flex-column justify-content-center'>
                                                    <Button
                                                        className="cookie-banner-button mb-3"
                                                        onClick={cookiesHandler}
                                                        color='primary'
                                                        variant='contained'
                                                    >
                                                        <IntlMessages id='label.cookies.acceptCookies' />
                                                    </Button>
                                                    <Button
                                                        className='tertiary-button mt-2'
                                                        onClick={() => setIsCustomizeDialogOpen(true)}
                                                        disableRipple
                                                        disableFocusRipple
                                                        variant='text'
                                                    >
                                                        <IntlMessages id='label.cookies.privacySettings' />
                                                    </Button>
                                                </div>
                                            </div>
                                        </CookieConsent>
                                        {
                                            isCustomizeDialogOpen && <CustomizeCookiesDialog
                                                isOpen={isCustomizeDialogOpen}
                                                setIsOpen={setIsCustomizeDialogOpen}
                                                cookieBannerHandler={cookieConsentRef.current}
                                            />
                                        }
                                    </div>
                                </IntlProvider>
                            </LocalizationProvider>
                        </EfzThemeProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </UserProvider>
        </CacheBuster>
    );
};

const mapStateToProps = ({ settings, user }) => {
    return {
        isDarkTheme: getIsDarkTheme(settings),
        locale: getLocale(settings),//re-render when change lang
        userID: getUserID(user),
        userCompanyID: getUserCompanyID(user)
    }
};

export default connect(mapStateToProps, {}
)(App);
