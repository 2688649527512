//Libraries
import { useMemo } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Button } from '@save2compete/efz-design-system';

//Components
import IntlMessages from 'components/util/IntlMessages';
import LanguageSwitcher from '../LanguageSwitcher';

//Styled Components
import { StyledMenuCard, StyledMenuCardDescription, StyledMenuCardIcon } from './index.styled';

//Constants
import { AVAILABLE_LANGUAGES } from 'constants/user';

//Services
import { getUserType } from 'services/user';

const HeaderMenuCard = ({
    handleOnClickGoTo,
    isThisPathActive,
    icon,
    label,
    type = 'default',
    isOpen = null,
    handleToggle = null,
    handleSwitchLanguage = null,
    langCode = null,
    handleUserSignOut = null,
    handleEditUserData = null,
    userTypeID = null,
    username = null,
    setDarkTheme = null
}) => {

    const languageCurrent = useMemo(() => AVAILABLE_LANGUAGES.find(language => language.icon === langCode), [langCode]);

    const renderCard = () => {
        switch(type) {
            case 'user':
                return <Dropdown
                    id='users-dropdown'
                    isOpen={isOpen}
                    toggle={handleToggle}
                >
                    <DropdownToggle
                        className="header-dropdown-toggle"
                        tag="span"
                        data-toggle="dropdown"
                    >
                        <StyledMenuCardIcon
                            isActive={isThisPathActive}
                        >
                            {icon}
                        </StyledMenuCardIcon>
                    </DropdownToggle>
    
                    <DropdownMenu end>
                        <div className="dropdown-header-section user-card">
                            <div className="header-section-text">
                                <h5 className="mb-0">
                                    {username}
                                </h5>
                                <h6 className='mb-0 mt-1'>
                                    {getUserType(userTypeID)}
                                </h6>
                            </div>
                            <div className="invisible">
                                {/* Set Dark Theme */}
                                <div className="p-2" onClick={setDarkTheme}>
                                    <span>
                                        <i className="zmdi zmdi-lamp dark-mode-icon" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown-header-section user-card">
                            <div className="d-flex flex-fill align-items-center justify-content-between">
                                <Button
                                    variant='tertiary'
                                    size='sm'
                                    icon='startArrowRight'
                                    onClick={handleEditUserData}
                                    dataTestId='edit-user-data'
                                >
                                    <IntlMessages id="label.userData" />
                                </Button>
                                <Button
                                    variant='tertiary'
                                    size='sm'
                                    icon='startLeaveArrowRight'
                                    onClick={handleUserSignOut}
                                    dataTestId='logout'
                                >
                                    <IntlMessages id="label.logout" />
                                </Button>
                            </div>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            case 'language':
                return <Dropdown
                    isOpen={isOpen}
                    toggle={handleToggle}
                >
                    <DropdownToggle
                        className="header-dropdown-toggle"
                        tag="span"
                        data-toggle="dropdown"
                    >
                        <StyledMenuCardIcon
                            isActive={isThisPathActive}
                        >
                            {languageCurrent.icon}
                        </StyledMenuCardIcon>
                    </DropdownToggle>
                    <DropdownMenu end className="w-auto transition-none">
                    <LanguageSwitcher
                        switchLanguage={handleSwitchLanguage}
                        handleRequestClose={handleToggle}
                        activeLanguage={langCode}
                    />
                </DropdownMenu>
            </Dropdown>
            default:
                return <StyledMenuCard onClick={handleOnClickGoTo}>
                    <StyledMenuCardIcon
                        isActive={isThisPathActive}
                    >
                        {icon}
                    </StyledMenuCardIcon>
        
                    <StyledMenuCardDescription                
                        isActive={isThisPathActive}
                    >
                        <h5>
                            <IntlMessages id={label} />
                        </h5>
                    </StyledMenuCardDescription>
                </StyledMenuCard>
        }
    }

    return (renderCard());
}

export default HeaderMenuCard;