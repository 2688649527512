import styled from 'styled-components';
import { neutrals, fonts } from 'constants/styles';

export const StyledMenuCard = styled.div.attrs({
    className: 'StyledMenuCard',
})`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 100%;
    cursor: pointer;

    ::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: ${neutrals[200]}; 
        margin: 0 1rem 0 0.5rem;    
    }
`;

export const StyledMenuCardIcon = styled.div.attrs({
    className: 'StyledMenuCardIcon',
})`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${neutrals[150]};
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-family: ${fonts.poppins};
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 100%;
    letter-spacing: 0%;
    margin: 0;
    color: ${props => props.isActive ? 'var(--primary-color)': `${neutrals[950]}`};
`;

export const StyledMenuCardDescription = styled.div.attrs({
    className: 'StyledMenuCardDescription',
})`
    h5 {
        font-family: ${fonts.poppins};
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 100%;
        letter-spacing: 0%;
        margin: 0;
        color: ${props => props.isActive ? 'var(--primary-color)': `${neutrals[950]}`};
    }
`;