/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description Page Header
 *
 * @version 20200918
 * @since 20200918 Initial release
 *
 */
//Libraries
import { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux'
import PropTypes from "prop-types";
import { useNavigate, Link } from "react-router-dom";

import useTheme from '@mui/material/styles/useTheme';

//Selectors
import { getLocale } from "selectors/settings";
import {
	getUserCompanyID,
	getUserUsername,
	getUserTypeID,
	getUserUserManagement
} from "selectors/user";

//Actions
import {
	setDarkTheme,
	switchLanguage
} from "actions/settings";
import {
	userSignOut,
	userSignOutADFS
} from "actions/user";

//constants
import {
	AVAILABLE_COMPANIES
} from 'constants/user';
import {
	BASENAME_URL,
	BASENAME_URL_PROCESSES,
	BASENAME_URL_OPPORTUNITIES,
	BASENAME_URL_USERS,
	BASENAME_URL_USER
} from 'constants/settings';

//our components
import HeaderMenuCard from 'components/header/MenuCard';
import LoadingDialog from 'components/LoadingDialog';
import LazyImage from 'components/util/LazyImage';

//Styles
import { StyledAppHeader } from './index.styled';

//Icons
import { ReactComponent as PoweredByIcon } from 'assets/images/logos/svg/powered-color.svg';
import { ReactComponent as OpportunitiesIcon } from 'assets/images/icons/svg/opportunities.svg';
import { ReactComponent as ProcessesIcon } from 'assets/images/icons/svg/processes.svg';
import { ReactComponent as UsersIcon } from 'assets/images/icons/svg/users.svg';
import { ReactComponent as AvatarIcon } from 'assets/images/icons/svg/avatar.svg';

//Services
import { getBoolean, isFieldDefined } from 'services/util/auxiliaryUtils';

/**
 * @context Page to Header
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Header = ({
	userTypeID,
	userCompanyID,
	userUsername,
	userUserManagement,
	isUADFS,
	// fnc
	setDarkTheme,
	userSignOut,
	switchLanguage,
	userSignOutADFS,
	...props
}) => {

	let navigate = useNavigate();

	const [isOpenInfoADFSDialog, setIsOpenInfoADFSDialog] = useState(false);
	const [isOpenLangSwitcher, setIsOpenLangSwitcher] = useState(false);
	const [isOpenUserInfo, setIsOpenUserInfo] = useState(false);

	const theme = useTheme();
	let currentCompany = useRef(AVAILABLE_COMPANIES.find(company => company.id === userCompanyID));


	useEffect(() => {
		window.addEventListener('resize', () => {
		});
	}, []);//eslint-disable-line


	// langSwitcherCard
	const langCode = props.locale.icon;
	const handleToggleLangSwitcherCard = useCallback(() => (setIsOpenLangSwitcher(!isOpenLangSwitcher)), [isOpenLangSwitcher]);//eslint-disable-line
	const handleSwitchLanguage = useCallback((locale) => (switchLanguage(locale)), []);//eslint-disable-line

	// UserCard
	const handleToggleUserCard = useCallback(() => (setIsOpenUserInfo(!isOpenUserInfo)), [isOpenUserInfo]);//eslint-disable-line
	const handleUserSignOut = useCallback(() => {
		if (isUADFS) setIsOpenInfoADFSDialog(true);
		return (isUADFS ? userSignOutADFS() : userSignOut())
	}, [isUADFS]); //eslint-disable-line
	const handleEditUserData = useCallback(async () => {
		handleToggleUserCard();
		return navigate(BASENAME_URL_USER);
	}, [isOpenUserInfo]);//eslint-disable-line


	// ProcesesCard
	const handleOnClickGoToProcesses = useCallback(() => (navigate(BASENAME_URL_PROCESSES)), []);//eslint-disable-line

	// UsersCard
	const handleOnClickGoToUsers = useCallback(() => (navigate(BASENAME_URL_USERS)), []);//eslint-disable-line

	// OpportunitiesCard
	const handleOnClickGoToOpportunities = useCallback(() => (navigate(BASENAME_URL_OPPORTUNITIES)), []);//eslint-disable-line

	const HEADER_CARDS = [
		{
			handleOnClick: handleOnClickGoToOpportunities, 
			isThisPathActive: window.location.pathname === BASENAME_URL_OPPORTUNITIES, 
			icon: <OpportunitiesIcon height={20} />, 
			label: "label.opportunities",
			show: true
		},
		{
			handleOnClick: handleOnClickGoToProcesses, 
			isThisPathActive: window.location.pathname === BASENAME_URL_PROCESSES, 
			icon: <ProcessesIcon height={16} />, 
			label: "label.processes",
			show: true
		},
		{
			handleOnClick: handleOnClickGoToUsers, 
			isThisPathActive: window.location.pathname === BASENAME_URL_USERS, 
			icon: <UsersIcon height={19} />, 
			label: "label.users",
			show: userUserManagement
		},
		{
			handleOnClick: handleOnClickGoToUsers, 
			isThisPathActive: window.location.pathname === BASENAME_URL_USERS, 
			icon: <></>, 
			label: "label.empty",
			show: true,
			type: 'language',
			isOpen: isOpenLangSwitcher,
			handleToggle: handleToggleLangSwitcherCard,
			handleSwitchLanguage: handleSwitchLanguage,
			langCode: langCode,
		},
		{
			handleOnClick: handleOnClickGoToUsers, 
			isThisPathActive: window.location.pathname === BASENAME_URL_USER, 
			icon: <AvatarIcon height={18} />, 
			label: "label.empty",
			show: true,
			type: 'user',
			isOpen: isOpenUserInfo,
			handleToggle: handleToggleUserCard,
			handleUserSignOut: handleUserSignOut,
			handleEditUserData: handleEditUserData,
			userTypeID: userTypeID,
			username: userUsername,
			setDarkTheme: setDarkTheme,
		},
	]

	return (
		<StyledAppHeader>
			<div className='header-logo'>
				<Link to={`${BASENAME_URL}app/opportunities`} >
					<LazyImage src={theme?.logo?.src} alt="efz" sizeImg={60} customClassName='placeholder-logo' />
					{
						<>
							{!!currentCompany.current?.logoCompanyName &&
								<span className="ms-3 w-100">
									<p>{currentCompany.current?.logoCompanyName}</p>
								</span>
							}
							{currentCompany.current?.usePoweredBy &&
								<PoweredByIcon className="ms-3 w-100" />
							}
						</>
					}
				</Link>
			</div>
			<div className='header-menu'>
				<ul>
					{
						HEADER_CARDS.map((card, index) => 
							card.show && <li key={index}>
								<HeaderMenuCard
									handleOnClickGoTo={card.handleOnClick}
									isThisPathActive={card.isThisPathActive}
									icon={card.icon}
									label={card.label}
									type={card?.type}
									isOpen={card?.isOpen}
									handleToggle={card?.handleToggle}
									handleSwitchLanguage={card?.handleSwitchLanguage}
									langCode={card?.langCode}
									handleUserSignOut={card?.handleUserSignOut}
									handleEditUserData={card?.handleEditUserData}
									userTypeID={card?.userTypeID}
									username={card?.username}
									setDarkTheme={card?.setDarkTheme}
								/>
							</li>
						)
					}
				</ul>
			</div>
			<LoadingDialog
				isOpen={isOpenInfoADFSDialog}
				title={'label.closing'}
				subtitle={'label.pleaseWait'}
			/>
		</StyledAppHeader>
	);

};


Header.propTypes = {
	userCompanyID: PropTypes.number.isRequired,
	userUsername: PropTypes.string.isRequired,
	userUserManagement: PropTypes.bool.isRequired,
	locale: PropTypes.shape({
		icon: PropTypes.string.isRequired,
		languageId: PropTypes.string.isRequired,
		locale: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
};


const mapStateToProps = ({ settings, user }) => {
	return {
		locale: getLocale(settings),
		userTypeID: getUserTypeID(user),
		userCompanyID: getUserCompanyID(user),
		userUsername: getUserUsername(user),
		userUserManagement: getUserUserManagement(user),
		isUADFS: isFieldDefined(localStorage.getItem('uadfs')) ? getBoolean(localStorage.getItem('uadfs')) : false,
	}


};

export default connect(mapStateToProps,
	{
		switchLanguage,
		setDarkTheme,
		userSignOut,
		userSignOutADFS
	})(Header);
