import styled from 'styled-components';
import { neutrals } from 'constants/styles';

export const StyledAppHeader = styled.nav.attrs({
    className: 'StyledAppHeader',
})`
    width: 100%;
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    z-index: 1051;
    box-shadow: 0px 2px 4px 0px #00000014;

    .header-logo {
        display: flex;

        a {
            display: flex;
            flex-wrap: nowrap;
            text-decoration: none;
            color: var(--text-color);
            align-items: center;

            img {
                max-height: 50px;

                @media (max-width: 575.98px), (max-height: 767.98px) {
                    max-height: 40px;
                }
            }

            svg {
                max-width: 6rem;

                @media (max-width: 575.98px), (max-height: 767.98px) {
                    max-width: 4rem;
                }
            }

            span {
                align-self: center;

                p {
                    margin: 0;
                    font-size: 1.5rem;
                    font-weight: 400;
                }
            }
        }
    }

    .header-menu {
        
        ul {
            display: flex;
            flex-wrap: nowrap;
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
        }

        .dropdown {
            display: flex;
            gap: 0.5rem;
            cursor: pointer;
            height: 100%;
            &#users-dropdown {
                ::after {
                    display: none;
                }
            }
            ::after {
                content: '';
                display: block;
                width: 1px;
                height: 100%;
                background-color: ${neutrals[200]}; 
                margin: 0 1rem 0 0.5rem;    
            }
        }

        .dropdown-menu {
            z-index: 9999;
            top: 14px !important;
            right: -15px !important;

            .language-list {
                ul {
                    display: flex;
                    flex-direction: column;
                }
                li {
                    padding: 0.6rem 0.5rem 0.6rem 1rem;
                    border-bottom: 0.5px solid #c4c4c4;
                }

                li:last-child {
                    border-bottom: 0;
                }

                li:hover {
                    background-color: var(--primary-color);

                    h5 {
                    color: var(--paper-color);
                    }

                    .default-svg--open svg path {
                    fill: var(--paper-color);
                    }
                }

                h5 {
                    color: var(--text-color);
                }
            }
        }
    }

    #users-dropdown {
        .dropdown-menu {
            padding: 16px;
            width: max-content;
        }
    }

    .user-card {
        align-items: unset;

        div {
            gap: 2rem;
        }

        .header-section-text {
            h5 {
                color: var(--text-color);
                font-size: 0.875rem;
            }

            h6 {
                color: var(--text-color-secondary);
                font-size: 0.75rem;
            }   
        }

        &.dropdown-header-section {
            border-bottom: none;

            &:last-of-type {
            padding-top: 0;
            }
        }

        .tertiary-button {
            margin: 0 !important;
            text-transform: uppercase;

            span {
            font-size: 0.75rem;
            }

            &:hover {
            color: var(--primary-color);
            }
        }
    }
    

`;