import { css } from 'styled-components';

const device = {
    mobile: '450px',
    tablet: '960px',
    desktop: '1360px',
    fhd: '1920px',
    _4k: '3840px',
};

export const media = {
    mobile: (...args) => css`
        @media (max-width: ${device.mobile}) {
            ${css(...args)};
        }
    `,
    tablet: (...args) => css`
        @media (max-width: ${device.tablet}) {
            ${css(...args)};
        }
    `,
    desktop: (...args) => css`
        @media (max-width: ${device.desktop}) {
            ${css(...args)};
        }
    `,
    fhd: (...args) => css`
        @media (max-width: ${device.fhd}) {
            ${css(...args)};
        }
    `,
    _4k: (...args) => css`
        @media (max-width: ${device._4k}) {
            ${css(...args)};
        }
    `,
    min: {
        mobile: (...args) => css`
            @media (min-width: ${device.mobile}) {
                ${css(...args)};
            }
        `,
        tablet: (...args) => css`
            @media (min-width: ${device.tablet}) {
                ${css(...args)};
            }
        `,
        desktop: (...args) => css`
            @media (min-width: ${device.desktop}) {
                ${css(...args)};
            }
        `,
        fhd: (...args) => css`
            @media (min-width: ${device.fhd}) {
                ${css(...args)};
            }
        `,
        _4k: (...args) => css`
            @media (min-width: ${device._4k}) {
                ${css(...args)};
            }
        `,
    },
};
export const fonts = {
    base: '"Open Sans", sans-serif',
    poppins: 'Poppins'
}

export const colors = {
    green_1: '#35B894',
    info: {
        200: '#C3DAF0',
        100: '#D7E7F5'
    },
    red: {
        efzRed50: '#fdf6f5',
        efzRed100: '#faecec',
        efzRed200: '#f6d9d8',
        efzRed300: '#f1c7c5',
        efzRed400: '#e8a19e',
        efzRed500: '#de7b77',
        efzRed600: '#d0433c',
        efzRed700: '#bb3c36',
        efzRed800: '#a63630',
        efzRed900: '#922f2a',
    }
}

export const neutrals = {
    950: '#0D0D0D',
    900: '#000000',
    800: '#202020',
    700: '#484447',
    600: '#a3a3a3',
    500: '#b1b1b1',
    400: '#C4C4C4',
    300: '#dcdcdc',
    200: '#e5e5e5',
    150: '#f0f0f0',
    100: '#f5f5f5',
    50: '#FAFAFA',
    0: '#FFF'
}